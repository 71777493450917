import IncomingCallComponent from './incomingCall'
import CallComponent from './call'
import MiddleComponent from './middle'
import AgentInfoComponent from './agentInfo'
import ContactComponent from './contact'


export {
  IncomingCallComponent,
  CallComponent,
  MiddleComponent,
  AgentInfoComponent,
  ContactComponent
}
